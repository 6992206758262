import React, { CSSProperties, FC, memo } from 'react';
import classNames from 'classnames';

import styles from './common.module.scss';

type Props = {
  icon: string;
  size?: string;
  className?: string;
  style?: CSSProperties;
  label?: string;
};

const CarIcon: FC<Props> = ({ icon, size, className, style, label }) => (
  <span
    className={classNames(`cars-icon-${icon}`, styles.carIcon, className)}
    style={{ ...style, fontSize: size }}
  >
    {label && <span className={styles.carIcon__label}>{label}</span>}
  </span>
);

CarIcon.defaultProps = {
  size: '12px',
  style: {},
  className: '',
  label: ''
};

export default memo(CarIcon);
