import React from 'react';
import Link from 'next/link';

const WithLink = (props) =>
  props.withLink ? (
    <Link href={props.href} as={props.as}>
      {props.children}
    </Link>
  ) : (
    props.children
  );

WithLink.defaultProps = {
  withLink: false,
  href: '',
  as: '',
  children: null
};

export default WithLink;
