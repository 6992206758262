import React from 'react';
import App from 'next/app';
import Head from 'next/head';
import { get } from 'lodash';
import i18next from 'i18next';
import matchAll from 'string.prototype.matchall';

import { ScreenProvider } from 'providers/screen-detect';
import { IframeProvider } from 'providers/iframe';
import { LanguageProvider } from 'providers/translations';

import 'assets/scss/style.scss';
import LayoutWrapper from 'layouts/layout-wrapper';
import { ThemeProvider } from 'providers/theme';
import { DevToolsProvider } from 'providers/dev-tools';

// import many locales
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/pl';
import 'moment/locale/ru';
import 'moment/locale/tr';
import 'moment/locale/es';
import 'moment/locale/it';
import 'moment/locale/cs';
import 'moment/locale/fr';
import ErrorBoundary from 'components/common/errors/error-boundary';
import { CarsApiConstructParams } from 'types/engine/cars-api-construct-params.type';

matchAll.shim();

// Initialize global modules
i18next.init({
  lng: 'de-DE',
  defaultNS: 'common'
});

class WebApp extends App {
  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <Head>
          <meta name="viewport" content="width=device-width,minimum-scale=1,initial-scale=1" />
          <meta name="robots" content="noindex, nofollow, noodp" />
        </Head>

        <LanguageProvider
          locale={get(pageProps, 'general.locale')}
          translations={get(pageProps, 'translations')}
          i18n={i18next}
        >
          <ScreenProvider ssrIsMobile={get(pageProps, 'general.ssrIsMobile')}>
            <DevToolsProvider initData={pageProps as CarsApiConstructParams}>
              <IframeProvider
                updateIframeUrl={get(pageProps, 'agent.layoutSettings.updateIframeUrl', false)}
              >
                <ThemeProvider
                  agent={get(pageProps, 'agent')}
                  headers={get(pageProps, 'customHeaders')}
                >
                  <LayoutWrapper initData={pageProps as CarsApiConstructParams}>
                    <ErrorBoundary>
                      <Component {...pageProps} />
                    </ErrorBoundary>
                  </LayoutWrapper>
                </ThemeProvider>
              </IframeProvider>
            </DevToolsProvider>
          </ScreenProvider>
        </LanguageProvider>
      </>
    );
  }
}
export default WebApp;
