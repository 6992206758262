import React, { FC } from 'react';
import useTranslation from 'providers/translations/use-translations';

import classNames from 'classnames';
import styles from './footer.module.scss';

interface Props {
  size?: 'default' | 'small';
}

const Certificates: FC<Props> = ({ size }) => {
  const { t } = useTranslation();

  return (
    <div
      className={classNames(styles.certificates, 'footer_certificates', {
        [styles['certificates__small']]: size === 'small'
      })}
    >
      <p className={classNames(styles.certificates__title, 'footer_certificates__title')}>
        {t('poweredby', 'Powered by Ypsilon.net AG')}
      </p>
      <ul className={styles.certificates__list}>
        <li>
          <a href="https://ypsilon.net/file/ypsilon-cert_iso_27001_en.pdf" title="ISO Certificate">
            <img
              className={styles['certificates__logo--reduced-opacity']}
              src="/img/certs/infaz-cert.png"
              alt="ISO Certificate"
            />
          </a>
        </li>
        <li>
          <img className={styles.certificates__logo} src="/img/certs/seal.png" alt="PCI DSS" />
        </li>
        <li>
          <img
            className={styles['certificates__logo--reduced-opacity']}
            src="/img/certs/list.png"
            alt="On the list! 2020"
          />
        </li>
      </ul>
    </div>
  );
};

Certificates.defaultProps = {
  size: 'default'
};

export default Certificates;
