import React, { FC, ReactNode } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';
import useCarsApi from 'providers/cars-api/use-cars-api';
import styles from '../common.module.scss';

type Props = {
  children: ReactNode;
  deactivateDynamicBackground?: boolean;
};

const DynamicLocationWrapper: FC<Props> = (props) => {
  const { agent } = useCarsApi();

  if (
    props.deactivateDynamicBackground === true ||
    !get(agent, 'layoutSettings.dynamicBackground', false)
  ) {
    return <>{props.children}</>;
  }

  return (
    <div className={classNames(styles.dynamicBackground__wrapper, 'dynamicBackgroundWrapper')}>
      {props.children}
    </div>
  );
};

DynamicLocationWrapper.defaultProps = {
  deactivateDynamicBackground: false
};

export default DynamicLocationWrapper;
