import React, { FC, memo } from 'react';
import { Translation } from 'types/engine/translation.type';
import classNames from 'classnames';

import styles from './forms.module.scss';

type Props = {
  label?: string;
  id?: string;
  errors?: Array<Translation>;
  required?: boolean;
  className?: string;
  emptyLabel?: boolean;
};

const InputLabel: FC<Props> = ({ label, id, errors, required, className, emptyLabel }) => {
  if (!label && emptyLabel !== true) return null;

  return (
    <label
      htmlFor={id}
      className={classNames(styles.fieldLabel, className, {
        [styles['fieldLabel--error']]: errors.length > 0,
        'fieldLabel--error': errors.length > 0
      })}
    >
      {emptyLabel !== true && label}
      {emptyLabel === true && (
        <span
          dangerouslySetInnerHTML={{
            __html: '&nbsp;'
          }}
        />
      )}
      {required && <span className={styles.fieldLabel__requiredStar}>*</span>}
    </label>
  );
};

InputLabel.defaultProps = {
  label: '',
  id: undefined,
  className: '',
  errors: [],
  required: false,
  emptyLabel: false
};

export default memo(InputLabel);
