import React, { CSSProperties, memo, ReactNode } from 'react';

import WithLink from 'components/common/with-link';
import CarIcon from 'components/common/car-icon';
import classNames from 'classnames';
import { ComponentSize } from './types';

import styles from './forms.module.scss';

type ButtonProps = {
  href?: string;
  as?: string;
  withLink?: boolean;
  text?: string;
  children?: ReactNode;
  icon?: string;
  isActive?: boolean;
  disabled?: boolean;
  onClick?: (e: any) => void;

  // Appearance
  className?: string;
  style?: CSSProperties;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'text';
  size?: ComponentSize;
  noUnderline?: boolean;
};

const Button: React.FC<ButtonProps> = (props) => {
  const CustomTag = props.href ? 'a' : 'button';

  const baseName = props.icon ? 'icoButton' : 'button';

  return (
    <>
      <WithLink href={props.href} as={props.as} withLink={props.withLink}>
        <CustomTag
          type={props.href ? undefined : 'button'}
          className={classNames(
            props.className,
            styles[baseName],
            styles[`${baseName}--${props.variant}`],
            `${props.variant}_button`,
            {
              [styles[`${baseName}--disabled`]]: props.disabled === true,
              [styles[`${baseName}--nounderline`]]: props.noUnderline,
              // [styles[`${baseName}--disabled`]]: props.noUnderline,
              [styles[`${baseName}--active`]]: props.isActive,
              [styles[`${baseName}--${props.size}`]]: props.size !== 'default'
            }
          )}
          disabled={props.disabled}
          style={props.style}
          onClick={props.onClick}
        >
          {props.icon ? (
            <CarIcon icon={props.icon} size="1em" />
          ) : (
            <>{props.children ? props.children : props.text}</>
          )}
        </CustomTag>
      </WithLink>
    </>
  );
};

Button.defaultProps = {
  withLink: false,
  isActive: false,
  disabled: false,
  style: {},
  variant: 'secondary',
  size: 'default',
  noUnderline: false,
  href: undefined,
  as: undefined,
  className: undefined,
  text: undefined,
  children: undefined,
  icon: undefined,
  onClick: () => null
};

export default memo(Button);
