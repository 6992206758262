import React, { FC, ReactNode, useEffect, useRef } from 'react';
import classNames from 'classnames';
import useOnclickOutside from 'react-cool-onclickoutside';

import useHasMounted from 'hooks/use-has-mounted';
import Button from 'components/forms/button';

import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import useIframeContext from 'providers/iframe/use-iframe-context';
import styles from './common.module.scss';
import { FadeIn } from './animations';

interface Props {
  open: boolean;
  display?: boolean;
  className?: string;
  classNameOuter?: string;
  classNameContent?: string;
  children?: ReactNode;
  width?: string;
  closeBtn?: boolean;
  title?: string;
  onClose?: () => void;
  inUse?: boolean;
  zIndex?: number;
  detectIFrame?: boolean;
  useClickOutside?: boolean;
  valign?: 'top' | 'center' | 'bottom';
  scrollMobileTop?: boolean;
}

const OverlayWrapper: FC<Props> = (props) => {
  const ref = useRef(null);
  const mounted = useHasMounted();
  const { isMobile } = useScreenDetect();
  const { inIframe, topEdge, bottomEdge } = useIframeContext();

  const backgroundClickEvent = (event) => {
    event.stopPropagation();
  };

  useOnclickOutside(
    () => {
      if (!mounted || !props.useClickOutside || !props.open || !props.inUse || !props.display) {
        return;
      }
      props.onClose();
    },
    {
      refs: [ref],
      detectIFrame: props.detectIFrame
    }
  );

  useEffect(() => {
    if (isMobile && props.open && props.display && props.inUse && props.scrollMobileTop === true) {
      window.scrollTo(0, 0);
    }

    document.body.style.overflow = props.open && props.display && props.inUse ? 'hidden' : 'auto';
  }, [props.open, props.display, props.inUse]);

  useEffect(
    () => () => {
      document.body.style.overflow = 'auto';
    },
    []
  );

  if (!props.inUse) {
    return <>{props.children}</>;
  }

  /* --------------------------------------------------------------------------------- RENDER --- */

  return (
    <FadeIn open={props.open}>
      <div
        className={classNames(
          styles.overlayWrapper,
          props.classNameOuter,
          [styles[`overlayWrapper__${props.valign}`]],
          {
            [styles['overlayWrapper--iframe']]: inIframe,
            [styles['overlayWrapper--ismobile']]: isMobile
          }
        )}
        style={{
          display: props.display ? 'flex' : 'none',
          top: inIframe ? `${topEdge}px` : undefined,
          bottom: inIframe ? `${bottomEdge}px` : undefined,
          zIndex: props.zIndex
        }}
        onClick={backgroundClickEvent}
      >
        <div
          ref={ref}
          className={classNames(styles.overlayWrapper__outerContainer, props.className)}
          style={{
            width: props.width
          }}
        >
          {(props.title || props.closeBtn) && (
            <div className={classNames(styles.overlayWrapper__head)}>
              <h2
                className={styles.overlayWrapper__title}
                dangerouslySetInnerHTML={{
                  __html: props.title
                }}
              />

              {props.closeBtn && (
                <Button
                  className={classNames(styles.overlayWrapper__closeBtn)}
                  icon="cross"
                  variant="text"
                  size="large"
                  onClick={props.onClose}
                />
              )}
            </div>
          )}

          <div className={classNames(styles.overlayWrapper__content, props.classNameContent)}>
            {props.children}
          </div>
        </div>
      </div>
    </FadeIn>
  );
};

OverlayWrapper.defaultProps = {
  display: true,
  width: '600px',
  closeBtn: false,
  className: null,
  classNameOuter: null,
  classNameContent: null,
  children: null,
  title: null,
  onClose: () => null,
  inUse: false,
  zIndex: 20,
  valign: 'center',
  detectIFrame: true,
  useClickOutside: true,
  scrollMobileTop: false
};

export default OverlayWrapper;
