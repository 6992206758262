import React, { createContext, FC, ReactNode, useState } from 'react';

import { defaultCustomHeaders, defaultLayoutSettings } from 'src/config';
import { LayoutTheme } from 'types/layout/layout-theme.type';
import { LayoutSettings } from 'types/layout/layout-settings.type';
import { Agent } from 'types/agent/agent.type';
import { CustomHeaders } from 'types/agent/custom-headers.type';

interface ThemeContextProps {
  children: ReactNode;
  agent?: Agent;
  headers?: CustomHeaders;
}

const themeContext = createContext<LayoutTheme>({
  settings: defaultLayoutSettings,
  customHeaders: defaultCustomHeaders,
  customCssUrl: ''
});

const ThemeProvider: FC<ThemeContextProps> = ({ children, agent, headers }) => {
  const [settings] = useState<LayoutSettings>(agent ? agent.layoutSettings : defaultLayoutSettings);
  const [customHeaders] = useState<CustomHeaders>(headers || defaultCustomHeaders);
  const [customCssUrl] = useState(agent ? agent.styles : '');

  return (
    <themeContext.Provider
      value={{
        settings,
        customHeaders,
        customCssUrl
      }}
    >
      {children}
    </themeContext.Provider>
  );
};

ThemeProvider.defaultProps = {
  agent: undefined,
  headers: undefined
};

export { themeContext, ThemeProvider };
